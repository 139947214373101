import React from 'react';
// import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardActions from '@material-ui/core/CardActions';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingBottom: theme.spacing(1),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
        paddingBottom: '0px',
        // minHeight: '178px',
        maxHeight: '178px',
    },
    cardButton: {
        backgroundColor: 'white'
    },
    cardActions: {
        padding: '8px',
        margin: 'auto'
    },
    cardDate: {
        color: 'grey'
    },
    link: {
        border: '1px solid black',
        padding: '8px',
        margin: 'auto',
        fontWeight: 'bolder',
        textShadow: '1px 1px #fff',
        fontSize: '15px',
        letterSpacing: '1px',
        color: 'black',
        textDecoration: 'none',
        backgroundColor: 'white',
        borderRadius: '8px',
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: theme.palette.primary.main
        }
        // boxShadow: '-3px -3px 5px 1px rgba(70, 130, 180, 0.38)',
        // paddingLeft: '5px',
        // paddingRight: '5px'
    }
}));

export default function news(props: any): JSX.Element {
    const classes = useStyles();
    const { posts } = props;

    return (
        <>
            <Container className={classes.cardGrid} maxWidth="xl">
                <Grid container spacing={2}>
                    {posts.map((card: any, idx: number) => (
                        <Grid item key={idx} xs={12} sm={6} md={3}>
                            <Card className={classes.card} elevation={8}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h6" component="h2">{card.title.replaceAll("_", " ")}</Typography>
                                    <Typography className={classes.cardDate}>{card.date}</Typography>
                                </CardContent>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={card.image}
                                    title={card.title}
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography>{card.text ? card.text.substr(0, 168) + "..." : null}</Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Link to={`/news/detail/${card.title}`} className={classes.link}>READ MORE...</Link>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}