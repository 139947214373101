import React from 'react';
import News from '../components/news';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    headline: {
        textAlign: 'center'
    },
    linkGroup: {
        justifyContent: 'center',
        // marginTop: 'px',
        display: "flex",
        // width: '100%',
        flexFlow: 'row wrap'
        // margin: 'auto'
    },
    pageCount: {
        marginTop: '10px',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px'
        // padding: 0,
        // backgroundColor: 'black'
    },
    linkCard: {
        marginTop: '1px',
        marginRight: '1px',
        padding: '15px 0px 15px 0px',
        width: '50px',
        // textAlign: 'center',
        // margin: 'auto',
        fontWeight: 'bolder',
        // textShadow: '1px 1px #fff',
        color: 'white',
        // fontSize: '15px',
        backgroundColor: theme.palette.secondary.main,
        textDecoration: 'none',
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: 'black'
        }
        // boxShadow: '-3px -3px 5px 1px rgba(70, 130, 180, 0.38)',
        // paddingLeft: '5px',
        // paddingRight: '5px'
    },
    linkButton: {
        color: 'white',
        // padding: '0px',
        // width: '20px',
        // display: 'inherit'
    },
    loading: {
        marginLeft: 'calc(49vw - 2vh)'
    }
}))


export default function NewsPaper(): JSX.Element {
    window.scrollTo(0, 0)
    const classes = useStyles();
    const params: any = useParams()
    const pageNumber = params.pageNumber;
    const [pageCount, setPageCount] = React.useState<any[]>([]);
    const [headline, setHeadline] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        setLoading(true)
        const newPageCount: any[] = [];
        let newHeadline: any[] = [];
        fetch(`${process.env.REACT_APP_HOST_NAME}/news/page/${pageNumber}`).then((res) => {
            return res.json()
        }).then((result) => {
            for (let index = 0; index < result.data.pageCount; index++) {
                newPageCount.push(<Link key={index} to={`/news/page/${index + 1}`} className={classes.linkCard}>{index + 1}</Link>)
            }
            newHeadline = result.data.news
            return result.data.news
        }).then((theNews) => {
            const allResult = [];
            for (let index = 0; index < theNews.length; index++) {
                allResult.push(fetch(theNews[index].content))
            }
            return Promise.all(allResult)
        }).then((result) => {
            const allText: any = []
            result.forEach((val) => {
                allText.push(val.text())
            })
            return Promise.all(allText)
        }).then((texts) => {
            for (let index = 0; index < texts.length; index++) {
                newHeadline[index].text = texts[index]
            }
            setPageCount(newPageCount)
            setHeadline(newHeadline)
            setLoading(false)
        }).catch(ex => {
            console.log(ex)
        })
    }, [pageNumber])
    return (
        <>
            <Helmet>
                <title>Berita mengenai PT. Tri Mustika Sejagat - Page {pageNumber}</title>
                <meta name="description" content="Produsen dan Distributor Baja Ringan (Kanal, Reng, Furing) - Besi (Hollow, Siku, Beton, dll) - Seng (BJLS, Genteng, Spandek, dll) - Gypsum - GRC di Sumatera" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <link rel="canonical" href={`https://trimustikasejagat.com/news/page/${pageNumber}`} />
                <meta property="og:title" content={`Berita mengenai PT. Tri Mustika Sejagat  - Page ${pageNumber}`} />
                <meta property="og:url" content={`https://trimustikasejagat.com/news/page/${pageNumber}`} />
            </Helmet>
            <Typography variant="h3" component="h1" className={classes.headline}>Berita</Typography>
            {loading ? <CircularProgress className={classes.loading} /> : <News posts={headline} />}
            <div className={classes.pageCount}>
                <Typography variant="h5" component="p">Pages</Typography>
                <div className={classes.linkGroup}>
                    {pageCount}
                </div>
            </div>
        </>
    );
}