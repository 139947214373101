import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(() => ({
    newsParagraph: {
        marginTop: '20px'
    },
    newsImage: {
        marginRight: '20px',
        flex: '50%'
    },
    newsContent: {
        display: window.innerWidth >= 800 ? "flex" : "inherit",
    }
}))


export default function NewsDetail(): JSX.Element {
    window.scrollTo(0, 0)
    const classes = useStyles()
    const params: any = useParams()
    const newsTitle = params.newsTitle;
    const [news, setNews] = React.useState({
        title: newsTitle, date: "", image: "", content: "", text: ""
    })
    React.useEffect(() => {
        let data: any = {}
        fetch(`${process.env.REACT_APP_HOST_NAME}/news/detail/${newsTitle}`).then((res) => {
            return res.json()
        }).then((result) => {
            data = result.data
            return data
        }).then((data) => {
            return fetch(data.content)
        }).then((res) => {
            return res.text()
        }).then((text) => {
            data.text = text;
            setNews(data)
        }).catch(ex => {
            console.log(ex)
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>{newsTitle.replaceAll("_", " ")} - PT. Tri Mustika Sejagat</title>
                <meta name="description" content={`${newsTitle.replaceAll("_", " ")} Produsen dan Distributor Baja Ringan - Besi - Seng - Gypsum - GRC di Sumatera`} />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <link rel="canonical" href={`https://trimustikasejagat.com/news/detail/${newsTitle}`} />
                <meta property="og:title" content={`PT. Tri Mustika Sejagat - ${newsTitle.replaceAll("_", " ")}`} />
                <meta property="og:url" content={`https://trimustikasejagat.com/news/detail/${newsTitle}`} />
                <meta property="og:image" content="https://trimustikasejagat.sgp1.digitaloceanspaces.com/news/BajaRinganSOSNI/Baja%20Ringan%20SO%20SNI.jpg" />
            </Helmet>
            <Container>
                <Typography component="h1" variant="h2">{news.title.replaceAll("_", " ")}</Typography>
                <Typography>{news.date}</Typography>
                <div className={classes.newsContent}>
                    <img className={classes.newsImage} src={news.image} width={window.innerWidth >= 800 ? "50%" : "100%"} />
                    <Typography className={classes.newsParagraph}>{news.text}</Typography>
                </div>
            </Container>
        </>
    );
}