import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import Navigation from './components/navigation';
import Home from './pages/home'
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './components/copyright'
import Contact from './components/contact'
import NewsPaper from './pages/newspaper'
import NewsDetail from './pages/newsdetail'
import About from './pages/about'
import Product from './pages/product'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    }
  },
}));

function App(): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters={true}>
        <Router>
          <Navigation />
          <Route exact path="/"><Home /></Route>
          <Route path="/home"><Home /></Route>
          <Route path="/products/page/:pageNumber"><Product /></Route>
          <Route path="/news/page/:pageNumber"><NewsPaper /></Route>
          <Route path="/about"><About /></Route>
          <Route path="/news/detail/:newsTitle"><NewsDetail /></Route>
        </Router>
        <Container maxWidth="xl" component="footer" className={classes.footer}>
          <Contact />
          <Copyright />
        </Container>
      </Container>
    </>
  );
}

export default App;
