import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CallIcon from '@material-ui/icons/Call';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import WAIcon from '@material-ui/icons/WhatsApp'
import EmailIcon from '@material-ui/icons/Email'
import IGIcon from '@material-ui/icons/Instagram'
import FBIcon from '@material-ui/icons/Facebook'

const useStyles = makeStyles((theme) => ({
    link: {
        color: "white",
        fontWeight: 'bolder',
        textShadow: '1px 1px black',
        paddingRight: '0px'
    },
    container: {
        backgroundColor: '#0f0f0f',
        // boxShadow: `1px 1px 10px 10px ${theme.palette.primary.main}`,
        marginTop: '15px',
        marginBottom: '15px'
    },
    gridTitle: {
        color: theme.palette.primary.main,
        textShadow: '1px 1px black',
        fontWeight: 'bolder'
    },
    icon: {}
}));

export default function contact(): JSX.Element {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={3} className={classes.container}>
                <Grid item xs={10} sm={3} >
                    <Typography variant="h6" color="textPrimary" gutterBottom className={classes.gridTitle}>
                        WhatsApp
                    </Typography>
                    <ul>
                        <li><Link href="https://api.whatsapp.com/send?phone=+6282166284011&text=Salam%20Sehat%20kepada%20Bapak/Ibu%20dari%20PT%20Tri%20Mustika%20Sejagat" variant="subtitle1" className={classes.link}><IconButton color="primary"><WAIcon className={classes.icon} /></IconButton >+62 821 6628 4011 </Link></li>
                        <li><Link href="https://api.whatsapp.com/send?phone=+6281269430205&text=Salam%20Sehat%20kepada%20Bapak/Ibu%20dari%20PT%20Tri%20Mustika%20Sejagat" variant="subtitle1" className={classes.link}><IconButton color="primary"><WAIcon className={classes.icon} /></IconButton >+62 812 6943 0205</Link></li>
                        <li><Link href="https://api.whatsapp.com/send?phone=+6282360696359&text=Salam%20Sehat%20kepada%20Bapak/Ibu%20dari%20PT%20Tri%20Mustika%20Sejagat" variant="subtitle1" className={classes.link}><IconButton color="primary"><WAIcon className={classes.icon} /></IconButton >+62 823 6069 6359</Link></li>
                        <li><Link href="https://api.whatsapp.com/send?phone=+6281263523502&text=Salam%20Sehat%20kepada%20Bapak/Ibu%20dari%20PT%20Tri%20Mustika%20Sejagat" variant="subtitle1" className={classes.link}><IconButton color="primary"><WAIcon className={classes.icon} /></IconButton >+62 812 6352 3502</Link></li>
                    </ul>
                </Grid>
                <Grid item xs={10} sm={3} >
                    <Typography variant="h6" color="textPrimary" gutterBottom className={classes.gridTitle}>
                        Phone
                    </Typography>
                    <ul>
                        <li><Link href="tel:+6281369985437" variant="subtitle1" className={classes.link}><IconButton color="primary"><CallIcon className={classes.icon} /></IconButton >+62 821 6628 4011</Link></li>
                        <li><Link href="tel:+6281369985437" variant="subtitle1" className={classes.link}><IconButton color="primary"><CallIcon className={classes.icon} /></IconButton >+62 812 6943 0205</Link></li>
                        <li><Link href="tel:+6281369985437" variant="subtitle1" className={classes.link}><IconButton color="primary"><CallIcon className={classes.icon} /></IconButton >+62 823 6069 6359</Link></li>
                        <li><Link href="tel:+6281369985437" variant="subtitle1" className={classes.link}><IconButton color="primary"><CallIcon className={classes.icon} /></IconButton >+62 812 6352 3502</Link></li>
                    </ul>
                </Grid>
                <Grid item xs={10} sm={3} >
                    <Typography variant="h6" color="textPrimary" gutterBottom className={classes.gridTitle}>
                        Social Network
                    </Typography>
                    <ul>
                        <li><Link href="https://www.facebook.com/trimustika.sejagat.3" variant="subtitle1" className={classes.link}><IconButton color="primary"><FBIcon className={classes.icon} /></IconButton >Tri Mustika Sejagat</Link></li>
                        <li><Link href="https://www.instagram.com/pt.trimustikasejagat/" variant="subtitle1" className={classes.link}><IconButton color="primary"><IGIcon className={classes.icon} /></IconButton >@pt.trimustikasejagat</Link></li>
                        <li><Link href="mailto:trimustikasejagat.id@gmail.com" variant="subtitle1" className={classes.link}><IconButton color="primary"><EmailIcon className={classes.icon} /></IconButton >trimustikasejagat.id</Link></li>
                    </ul>
                </Grid>
                <Grid item xs={10} sm={3} >
                    <Typography variant="h6" color="textPrimary" gutterBottom className={classes.gridTitle}>
                        Alamat
                    </Typography>
                    <Typography className={classes.link}>
                        Jl. Tengku Amir Hamzah No.168 A, Jati Karya, Kec. Binjai Utara, Kota Binjai, Sumatera Utara 20351
                    </Typography>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.7503150493144!2d98.5082238144465!3d3.644327250925955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x303129f59100d34b%3A0x977055bf39336f8e!2sPT.%20Tri%20Mustika%20Sejagat%20-%20Produsen%20Rangka%20Atap%20Baja%20Ringan%20(Kanal%20%26%20Reng)%2C%20Seng%20(BJLS%2C%20Spandek%2C%20Genteng)%20%7C%20Distributor%20Besi%20(Hollow%2C%20Beton%2C%20Siku%2C%20dll)%20dan%20Bahan%20Bangunan!5e0!3m2!1sid!2sid!4v1619681202696!5m2!1sid!2sid" width="300" height="150" allowFullScreen={false} loading="lazy"></iframe>
                </Grid>
            </Grid>
        </>
    );
}