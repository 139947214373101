import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
    product: {
        textAlign: 'center'
    },
    linkGroup: {
        justifyContent: 'center',
        // marginTop: 'px',
        display: "flex",
        // width: '100%',
        flexFlow: 'row wrap'
        // margin: 'auto'
    },
    pageCount: {
        marginTop: '10px',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px'
        // padding: 0,
        // backgroundColor: 'black'
    },
    linkCard: {
        marginTop: '1px',
        marginRight: '1px',
        padding: '15px 0px 15px 0px',
        width: '50px',
        // textAlign: 'center',
        // margin: 'auto',
        fontWeight: 'bolder',
        // textShadow: '1px 1px #fff',
        color: 'white',
        // fontSize: '15px',
        backgroundColor: theme.palette.secondary.main,
        textDecoration: 'none',
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: 'black'
        }
        // boxShadow: '-3px -3px 5px 1px rgba(70, 130, 180, 0.38)',
        // paddingLeft: '5px',
        // paddingRight: '5px'
    },
    linkButton: {
        color: 'white',
        // padding: '0px',
        // width: '20px',
        // display: 'inherit'
    },
    loading: {
        marginLeft: 'calc(48vw - 4vh)'
    },
    productImage: {
        padding: '1%'
    }
}))


export default function NewsPaper(): JSX.Element {
    window.scrollTo(0, 0)
    const classes = useStyles();
    const params: any = useParams()
    const pageNumber = params.pageNumber;
    const [pageCount, setPageCount] = React.useState<any[]>([]);
    const [products, setProducts] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        setLoading(true)
        const newPageCount: any[] = [];
        fetch(`${process.env.REACT_APP_HOST_NAME}/products/page/${pageNumber}`).then((res) => {
            return res.json()
        }).then((result) => {
            for (let index = 0; index < result.data.pageCount; index++) {
                newPageCount.push(<Link key={index} to={`/products/page/${index + 1}`} className={classes.linkCard}>{index + 1}</Link>)
            }
            setPageCount(newPageCount)
            setProducts(result.data.products)
            setLoading(false)
        }).catch(ex => {
            console.log(ex)
        })
    }, [pageNumber])
    return (
        <>
            <Helmet>
                <title>Produk di PT. Tri Mustika Sejagat - Page {pageNumber}</title>
                <meta name="description" content="List Produk Bahan Bangunan yang tersedia di PT. Tri Mustika Sejagat" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <link rel="canonical" href={`https://trimustikasejagat.com/products/page/${pageNumber}`} />
                <meta property="og:title" content={`Produk di PT. Tri Mustika Sejagat  - Page ${pageNumber}`} />
                <meta property="og:url" content={`https://trimustikasejagat.com/products/page/${pageNumber}`} />
                <meta property="og:image" content={`https://trimustikasejagat.sgp1.digitaloceanspaces.com/list_produk.jpg`} />
            </Helmet>
            <Typography variant="h3" component="h1" className={classes.product}>Produk</Typography>
            <Container maxWidth="xl">
                {loading ? <CircularProgress className={classes.loading} /> : products.map((val, idx) => {
                    return (
                        <img key={idx} src={val.image} width={window.innerWidth >= 800 ? "50%" : "100%"} className={classes.productImage} />
                    )
                })}
                <div className={classes.pageCount}>
                    <Typography variant="h5" component="p">Pages</Typography>
                    <div className={classes.linkGroup}>
                        {pageCount}
                    </div>
                </div>
            </Container >
        </>
    );
}