import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import iconTMS from '../images/icon.jpg'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarIcon: {
        marginRight: '10px',
        borderRadius: '20px'
    },
    toolbarTitle: {
        // flexGrow: 1,
        fontSize: '25px',
        fontWeight: 'bolder',
        textShadow: '1px 1px #fff',
        padding: '10px 30px 10px 10px',
        borderRight: '1px solid black'
    },
    link: {
        margin: 'auto',
        fontWeight: 'bolder',
        textShadow: '1px 1px #fff',
        fontSize: '15px',
        letterSpacing: '1px',
        color: 'black',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: "underline"
        }
        // boxShadow: '-3px -3px 5px 1px rgba(70, 130, 180, 0.38)',
        // paddingLeft: '5px',
        // paddingRight: '5px'
    },
}));

export default function navigation(): JSX.Element {
    const classes = useStyles();
    return (
        <>
            <AppBar position="sticky" elevation={10} className={classes.appBar}>
                <Toolbar component="nav" className={classes.toolbar}>
                    {window.innerWidth >= 800 ? (
                        <>
                            <img alt="TMS" src={iconTMS} className={classes.toolbarIcon} />
                            <Typography noWrap className={classes.toolbarTitle}>PT. Tri Mustika Sejagat</Typography>
                        </>) : null}
                    <Link to="/home" className={classes.link}>HOME</Link>
                    <Link to="/products/page/1" className={classes.link}>PRODUCT</Link>
                    <Link to="/news/page/1" className={classes.link}>BERITA</Link>
                    <Link to="/about" className={classes.link}>ABOUT US</Link>
                </Toolbar>
            </AppBar>
        </>
    );
}