import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffcc00',
        },
        secondary: {
            main: '#1e3c72',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#FFF',
        },
    },
    typography: {
        fontFamily: () => window.innerWidth >= 800 ? "Didot" : "sans-serif"
    }
});

export default theme;