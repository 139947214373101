import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
    aboutTitle: {
        textAlign: 'center'
    },
    aboutParagraph: {},
    aboutImages: {
        display: 'flex',
        flexFlow: 'row wrap'
    }
}))


export default function About(): JSX.Element {
    const classes = useStyles()
    window.scrollTo(0, 0)
    return (
        <>
            <Helmet>

                <title>Tentang Perusahaan PT. Tri Mustika Sejagat</title>
                <meta name="description" content="PT. Tri Mustika Sejagat selalu mengutamakan Kejujuran, Kualitas dan Komitmen" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <link rel="canonical" href="https://trimustikasejagat.com/about" />
                <meta property="og:title" content="Tentang Perusahaan PT. Tri Mustika Sejagat" />
                <meta property="og:description" content="PT. Tri Mustika Sejagat selalu mengutamakan Kejujuran, Kualitas dan Komitmen" />
                <meta property="og:url" content="https://trimustikasejagat.com/about" />
                <meta property="og:image" content="https://trimustikasejagat.sgp1.digitaloceanspaces.com/news/KebijakanMutu/Kebijakan%20Mutu.jpg" />
            </Helmet>
            <Container maxWidth="xl">
                <Typography variant="h3" component="h1" className={classes.aboutTitle}>Tentang Perusahaan</Typography>
                <Typography variant="h6" component="p" className={classes.aboutParagraph}>{`PT. Tri Mustika Sejagat merupakan perusahaan yang bergerak di bidang bahan/material bangunan dan telah memproduksi baja ringan, mendistribusi berbagai macam bahan bangunan dan telah menjangkau seluruh Pulau Sumatera. Prinsip pada perusahaan PT. Tri Mustika Sejagat adalah mengutamakan Kejujuran, Kualitas dan Komitmen.`}</Typography>
                <Divider />
                <Typography variant="h6" component="p" className={classes.aboutParagraph}>{`Perushaan telah memproduksi baja ringan sendiri dikarenakan permintaan pasaran terhadap produk baja ringan meningkat pesat, dengan demikian Perusahaan mampu menyediakan produk baja ringan dalam quantity yang banyak. Perusahaan juga merupakan distributor dan mampu men-supply material bangunan dalam quantity yang banyak`}</Typography>
                <Divider />
                <Typography variant="h6" component="p" className={classes.aboutParagraph}>{`Untuk menunjukkan komitmen perusahaan dalam menyediakan produk yang berkualitas maka perusahaan telah memiliki sertifikasi ISO 9001:2015, PT. Tri Mustika Sejagat juga memiliki sertifikasi SNI 8399:2017 Profil Baja Ringan untuk baja ringan merek "SO" yang diproduksi sendiri oleh perusahaan.`}</Typography>
                <Typography variant="h6" component="p" className={classes.aboutParagraph}>{``}</Typography>
                <div className={classes.aboutImages}>
                    <img width={window.innerWidth >= 800 ? '50%' : '100%'} src="https://trimustikasejagat.sgp1.digitaloceanspaces.com/news/SNI/SNI.jpg" />
                    <img width={window.innerWidth >= 800 ? '50%' : '100%'} src="https://trimustikasejagat.sgp1.digitaloceanspaces.com/truss%20so%20spanduk%20tinggi.jpg" />
                </div>
            </Container>
        </>
    );
}