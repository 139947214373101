
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function copyright(): JSX.Element {
    return (
        <>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}{` ${new Date().getFullYear()}.`}<Link color="inherit" href="https://trimustikasejagat.com/">PT. Tri Mustika Sejagat</Link>
            </Typography>
        </>
    );
}