import React from 'react';
import Intro from '../components/intro';
import News from '../components/news';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';

const mainFeaturedPost = {
    title: 'PT. Tri Mustika Sejagat Produsen Baja Ringan dan Distributor Seng, Besi, DLL',
    description:
        "Produsen dan Distributor Baja Ringan (Kanal, Reng, Furing) - Besi (Hollow, Siku, Beton, dll) - Seng (BJLS, Genteng, Spandek, dll) - Gypsum - GRC di Sumatera",
    image: window.innerWidth >= 800 ? 'https://trimustikasejagat.sgp1.digitaloceanspaces.com/truss%20so%20spanduk%20panjang.jpg' : 'https://trimustikasejagat.sgp1.digitaloceanspaces.com/truss%20so%20spanduk%20tinggi.jpg',
    imgText: 'PT. Tri Mustika Sejagat - Produsen Baja Ringan, Besi, Seng'
};

const useStyles = makeStyles(() => ({
    headline: {
        textAlign: 'center'
    }
}))


export default function Home(): JSX.Element {
    window.scrollTo(0, 0)
    const classes = useStyles();
    const [headline, setHeadline] = React.useState<any[]>([])
    React.useEffect(() => {
        let newHeadline: any[] = [];
        fetch(`${process.env.REACT_APP_HOST_NAME}/news/headline`).then((res) => {
            return res.json()
        }).then((result) => {
            newHeadline = result.data
            return result.data
        }).then((headline) => {
            const allResult = [];
            for (let index = 0; index < headline.length; index++) {
                allResult.push(fetch(headline[index].content))
            }
            return Promise.all(allResult)
        }).then((result) => {
            const allText: any = []
            result.forEach((val) => {
                allText.push(val.text())
            })
            return Promise.all(allText)
        }).then((texts) => {
            for (let index = 0; index < texts.length; index++) {
                newHeadline[index].text = texts[index]
            }
            setHeadline(newHeadline)
        }).catch(ex => {
            console.log(ex)
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>PT. Tri Mustika Sejagat Produsen Baja Ringan dan Distributor Seng, Besi, DLL</title>
                <meta name="description" content="Produsen dan Distributor Baja Ringan (Kanal, Reng, Furing) - Besi (Hollow, Siku, Beton, dll) - Seng (BJLS, Genteng, Spandek, dll) - Gypsum - GRC di Sumatera" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <link rel="canonical" href="https://trimustikasejagat.com/home" />
                <meta property="og:title" content="PT. Tri Mustika Sejagat Produsen Baja Ringan dan Distributor Besi, Seng, dan Bahan Bagunan Lainnya di Sumatera" />
                <meta property="og:description" content="Produsen dan Distributor Baja Ringan (Kanal, Reng, Furing) - Besi (Hollow, Siku, Beton, dll) - Seng (BJLS, Genteng, Spandek, dll) - Gypsum - GRC di Sumatera" />
                <meta property="og:url" content="https://trimustikasejagat.com/home" />
                <meta property="og:image" content="https://trimustikasejagat.sgp1.digitaloceanspaces.com/news/BajaRinganSOSNI/Baja%20Ringan%20SO%20SNI.jpg" />

            </Helmet>
            <Intro post={mainFeaturedPost} />
            <Typography variant="h3" component="h2" className={classes.headline}>Headline News</Typography>
            {headline.length > 0 ? <News posts={headline} /> : null}
        </>
    );
}